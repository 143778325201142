<template>
  <v-row
    class="pc-login-btn-container mx-0"
    :style="{ width: width, height: height, marginTop: marginTop }"
    align="center"
    justify="center"
    @click="clickBtn"
  >
    <slot></slot>
  </v-row>
</template>

<script>
export default {
  name: "pc-login-btn",
  props: {
    width: {
      type: String,
      default: "400px",
    },
    height: {
      type: String,
      default: "64px",
    },
    marginTop: {
      type: String,
      default: "40px",
    },
  },
  data() {
    return {};
  },
  components: {},
  computed: {},
  methods: {
    clickBtn() {
      this.$emit("clickBtn");
    },
  },
  watch: {},
  created() {},
};
</script>

<style lang='scss' scoped>
.pc-login-btn-container {
  background: #ffffff;
  border-radius: 34px;
  border: 1px solid #4d9f7c;
  line-height: 18px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #4d9f7c;
}
.pc-login-btn-container:hover {
  background: #8dcbb0;
  color: white;
}
</style>