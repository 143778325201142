<template>
  <div class="pc-login-container">
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0" cols="6">
        <v-img
          :style="{ marginTop: imgMarginTop, marginLeft: imgMarginLeft }"
          :width="imgWidth"
          :height="imgHeight"
          src="@/assets/img_pc_login.png"
          contain
        ></v-img>
      </v-col>

      <v-col class="pa-0 ma-0" cols="6">
        <v-col
          class="pa-0 my-0"
          :style="{ width: colWidth, marginLeft: colMarginLeft }"
        >
          <v-row
            class="pa-0 mx-0"
            :style="{ marginTop: titleMarginTop }"
            justify="center"
          >
            <div class="pc-login-title">登录</div>
          </v-row>

          <pc-login-btn
            :width="btnWidth"
            :height="btnHeight"
            :marginTop="btnMarginTop"
            @clickBtn="loginStudent"
            >学生端
          </pc-login-btn>

          <pc-login-btn
            :width="btnWidth"
            :height="btnHeight"
            :marginTop="btnMarginTop"
            @clickBtn="loginTeacher"
            >教师端
          </pc-login-btn>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PcMixins from "@/views/pc/_mixins/pc-mixins.js";
import PcLoginBtn from "@/views/pc/components/pc-login-btn";
export default {
  name: "pc-login",
  mixins: [PcMixins],
  props: {},
  data() {
    return {
      screenWidth: 0,
      screenHeight: 0,
      imgWidth: 510,
      imgHeight: 900,
      imgMarginLeft: "346px",
      imgMarginTop: "33px",
      colWidth: "400px",
      colMarginLeft: "200px",
      titleMarginTop: "371px",
      btnWidth: "400px",
      btnHeight: "64px",
      btnMarginTop: "40px",
    };
  },
  components: {
    PcLoginBtn,
  },
  computed: {},
  methods: {
    //点击学生端
    loginStudent() {
      this.$router.push({ name: "pcLoginStudent" });
      // this.$router.replace({ name: "pcLoginStudent" });
    },
    //点击教师端
    loginTeacher() {
      this.$router.push({ name: "pcLoginTeacher" });
      // this.$router.replace({ name: "pcLoginTeacher" });
    },
  },
  watch: {},
  created() {
    this.screenWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    this.screenHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    this.imgWidth = Math.round((this.screenWidth * 510) / 1920);
    this.imgHeight = Math.round((this.screenHeight * 900) / 1080);
    this.imgMarginLeft = Math.round((this.screenWidth * 346) / 1920) + "px";
    this.imgMarginTop = Math.round((this.screenHeight * 33) / 1080) + "px";
    this.colWidth = Math.round((this.screenWidth * 400) / 1920) + "px";
    this.colMarginLeft = Math.round((this.screenWidth * 200) / 1920) + "px";
    this.titleMarginTop = Math.round((this.screenHeight * 371) / 1080) + "px";
    this.btnWidth = Math.round((this.screenWidth * 400) / 1920) + "px";
    this.btnHeight = Math.round((this.screenHeight * 64) / 1080) + "px";
    this.btnMarginTop = Math.round((this.screenHeight * 40) / 1080) + "px";
  },
};
</script>

<style lang="scss" scoped>
.pc-login-container {
  width: 100%;
  height: 100%;
  background: url("~@/assets/bg_pc_login.png") no-repeat center;
  background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  .pc-login-title {
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #444444;
  }
}
</style>
